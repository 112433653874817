import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./Picker.scss";

import small_arrow from "../../assets/icons/small_arrow.svg";


import { OvalButton } from '../../atoms/Button/OvalButton';

export const Picker = ({id, picked_index, picker_objects}) => {   
    
    picker_objects = [
        {
            id: 1,
            text: "Шоссейная",
            color: 'green',
            disabled: true
        },
        {
            id: 2,
            text: "АТ",
            color: 'orange',
            disabled: true
        },
        {
            id: 3,
            text: "МТ",
            color: 'red',
            disabled: true
        }
    ]

    picker_objects[picked_index]["disabled"] = false;

    const arrow_angle = ((104 / (picker_objects.length-1)) * (picked_index)) + 38;

    const max_margin_bottom = 50;

    let multipyer = Math.floor((picker_objects.length) / 2) + 1;
    for (let i = 0; i < picker_objects.length; i++) {
        picker_objects[i]["style"] = {
            marginBottom: Math.floor(max_margin_bottom/multipyer) + "px",
        }

        if (i < ((picker_objects.length - 1) / 2)) {
            multipyer -= 1;
        } else {
            multipyer += 1;
        }
    }

    return (
            <div className='Picker'>
                
                <div className='Picker--row'>
                    
                    {picker_objects.map((picker_object, index) => { 

                        return (
                            <div key={index} style={picker_object.style} className='Picker--row--item'>
                                <OvalButton 
                                    text={picker_object.text} 
                                    color={picker_object.color}
                                    disabled={picker_object.disabled}
                                />
                            </div>
                        );
                    })}

                </div>
                    <div>
                        <img style={{transformOrigin: '80% 80%', transform: `rotate(${arrow_angle}deg)`}} src={small_arrow}/>
                    </div>
                <div>

                </div>
            </div>
        
    )
}

