import React, {Suspense} from 'react';

import Feature from 'ol/Feature.js';
import Geolocation from 'ol/Geolocation.js';
import Map from 'ol/Map.js';
import Point from 'ol/geom/Point.js';
import View from 'ol/View.js';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';

import {transform} from 'ol/proj'

import {getCenter} from 'ol/extent';
import {getArea} from 'ol/extent';


import './MiniMapView.scss';


import GeoJSON from 'ol/format/GeoJSON.js';

import {
  Select,
  defaults as defaultInteractions,
} from 'ol/interaction.js';
import { none } from 'ol/centerconstraint';


const styles = {
  'LineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 3,
    }),
  }),

  'easy': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 3,
      lineDash: [10, 10],
    }),
  }),
  'medium': new Style({
    stroke: new Stroke({
      color: '#FFA318',
      width: 3,
      lineDash: [10, 10],
    }),
  }),
  'hard': new Style({
    stroke: new Stroke({
      color: '#E90000',
      width: 3,
      lineDash: [10, 10],
    }),
  }),
  'uploaded': new Style({
    stroke: new Stroke({
      color: '#9E00E9',
      width: 3,
      lineDash: [10, 10],
    }),
  }),

  'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 3,
    }),
  })
};

const styleFunction = function (feature) {
  let style = styles[feature.values_.level];  // styles[feature.getGeometry().getType()];
  return style;
};



export class MiniMapView extends React.Component {
  constructor({onTrackClick, geoJSONLink}) {
    super();
    this.onTrackClick = onTrackClick;
    this.geoJSONLink = geoJSONLink;
    this.state = {
      auth_status_code: 0,
      auth_message: ""
    }
    this.map = null;
    this.toRender = <></>;
    
  }

  componentDidMount = () => {
    this.setupSelect();
    this.setupMiniMapView();
    console.log("mounting");
    this.getGeoJSON(this.map);
  }

  componentWillUnmount = () => {
    // console.log("do you whant to leave?")
    // alert("do you whant to leave?");
    
  }

  flyTo = (location, done) => {
    const duration = 2000;
    const zoom = Math.max(this.view.getZoom(), 14);
    let parts = 2;
    let called = false;
    function callback(complete) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        done(complete);
      }
    }
    this.view.animate(
      {
        center: location,
        duration: duration,
      },
      callback
    );
    this.view.animate(
      {
        zoom: zoom - 1,
        duration: duration / 2,
      },
      {
        zoom: zoom,
        duration: duration / 2,
      },
      callback
    );
  }
  


  setupSelect = () => {
    this.select = new Select({
      wrapX: false,
      hitTolerance: 10
    });
    
    this.select.on("select", (e) => {
      if (this.onTrackClick){
        this.onTrackClick(e, this.select)
      }
    })
  }

  setupMiniMapView = () => {
    this.view = new View({
      center: [4222538.68101781, 7479960.285750981],
      zoom: 9,
    });
    
    const map = new Map({
      // interactions: defaultInteractions().extend([this.select]),
      interactions: [],
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      target: 'minimap',
      view: this.view,
      controls: []
    });
    
    this.map = map;
  }


  getGeoJSON = (map) => {
    const url = this.geoJSONLink;    //'http://localhost:3000/style.geojson'; // '/style.geojson'; //'https://openlayers.org/data/vector/ecoregions.json';
    console.log("url: ", url);
    const vs = new VectorSource({
      url: url,
      format: new GeoJSON()
    })
    const some_layer = new VectorLayer({
      map: map,
      source: vs,
      style: styleFunction,
    })
    window.vector_source = vs;
    vs.on("featuresloadend", (e) => {
      // console.log("extent: ", vs.getExtent());
      // console.log("getArea: ", getArea(vs.getExtent()) );
      // console.log("Center: ", getCenter(vs.getExtent()) );
      map.getView().fit(vs.getExtent(), map.getSize()); 
      // this.flyTo(getCenter(vs.getExtent()), function () {})
    })
    
    // this.flyTo()

    // some_layer.values_.visible = false;

  }

  render = () => {
    return (
          <div className="MiniMapView">
            
            {this.state.toRender}
            

              <div id="minimap" className="minimap">

                <div className="extra">
                </div>
              
                <div id="info">
                
                </div>
                
                {/* <label htmlFor="track">
                  track position
                  <input id="track" type="checkbox"/>
                </label>
                <p>
                  position accuracy : <code id="accuracy"></code>&nbsp;&nbsp;
                  altitude : <code id="altitude"></code>&nbsp;&nbsp;
                  altitude accuracy : <code id="altitudeAccuracy"></code>&nbsp;&nbsp;
                  heading : <code id="heading"></code>&nbsp;&nbsp;
                  speed : <code id="speed"></code>
                </p> */}
              </div>              
          </div>
      );
    }
}

export default MiniMapView;
