import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./TyreType.scss";

import tyre_type_picker_road from "../../assets/icons/tyre_type_picker_road.svg";

import { CardButton } from '../../atoms/Button/OvalButton';

import { Picker } from '../../molecules/Picker/Picker';


export const TyreType = ({id, typeNumber}) => {   

    return (
        <div id={id} className='TyreType'>
            <h3>Резина:</h3>

            <Picker picked_index={typeNumber}/>
            {/* <div className='TyrePicker'>
                
                <div className='TyrePicker--row'>
                    <div></div>
                    <CardButton text={"Шоссейная"}/>
                    
                    
                    
                    
                    <div className='TyrePicker--row--middle'>
                        <CardButton text={"____AT____"}/>
                    </div>
                    
                    <CardButton text={"____МТ____"}/>
                </div>
                <div>
                    /\
                </div>
                <div>

                </div>
            </div> */}
            
        </div>
        
    )
}

