import React from 'react';

import { CloseButton } from '../../atoms/CloseButton/CloseButton';

import { Placeholder } from '../../atoms/Placeholder/Placeholder'

import { MiniMapView } from '../../organisms/MiniMapView/MiniMapView';

import { DifficultyBar } from '../../molecules/DifficultyBar/DifficultyBar';

import { TyreType } from '../../molecules/TyreType/TyreType';


import { CircleButton } from '../../atoms/CircleButton/CircleButton';



import './TrackInfoViewer.scss';

import { fetchModule } from '../../fetch'

import { metric } from '../../metric';


import navigation_arrow from "../../assets/icons/navigation_arrow.svg";
import share_icon from "../../assets/icons/share_icon.svg";


import {serverUrl} from "../../fetch";
import { async } from 'q';


// export const TrackInfoViewer = ({id, closeFunc}) => {
export class TrackInfoViewer extends React.Component {

    constructor({id, closeFunc}) {
        super();
        this.id = id;
        this.closeFunc = closeFunc;
        this.state = {
            loaded: false,
            track:{
                name: "",
                description: "",
                difficulty: -1,
                tyres: 0,
                navigate_to_link: ""   
            }

        }
    }

    componentDidMount = () => {
        metric.page_visit("/track");
        this.getTrackInfo(this.id);
    }


    getTrackInfo = async (track_id) => {
        try {
          const response = await fetchModule.doGet({path: '/track/'+track_id});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              
              this.setState(state => ({
                ...state, 
                track: json,
                loaded: true
              }));  
              metric.received_data('/track/'+track_id)
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            metric.error("/track", {track_id: track_id, http_code: response.status});
            let json = await response.json();
            return json;
          } 
          else {
                metric.error("/track", {track_id: track_id, http_code: response.status});
                throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    navigate_to_direction = (e) => {
        if (this.state.track.navigate_to_link.length){
            metric.click("open_directions_in_yandex", {track_id: this.id})
            setTimeout(() => { window.open(this.state.track.navigate_to_link, '_blank').focus(); }, 300);
        }
    }

    share_track = async (e) => {
        console.log("share click");
        const shareData = {
            title: "Offmaps",
            text: "Смотри какой маршрут я нашел: " + this.state.track.name,
            url: window.location.href,
        };

        try {
            await navigator.share(shareData);
            console.log("shared");
            metric.click("share", {track_id: this.id})
        } catch (err) {
            console.log("cant share");
            metric.error("share", {track_id: this.id});
        }
    }


    render = () => {
        return(
            <div className="TrackInfoViewer" key={this.id.toString()}>
                <div>
                    <div className="TrackInfoViewer--elements">
                        <div className="TrackInfoViewer--elements--header">
                            {this.state.loaded ? <h3>{this.state.track.name}</h3> : <Placeholder width="150px" height="20px"/>}
                            <CloseButton onClick={this.closeFunc}/>
                        </div>
                        <div className='scrollable'>
                            {this.state.loaded
                            ?   <div className="TrackInfoViewer--elements--minimap">
                                    <MiniMapView onTrackClick={this.onTrackClick} geoJSONLink={serverUrl + '/track/' + this.id + '/geojson'}/>
                                </div>
                            :   <div className="TrackInfoViewer--elements--minimap--placeholder">
                                    <Placeholder width="calc(100% - 30px)" height="200px"/>
                                </div>
                            }
                            
                            <br/>
                            {this.state.loaded ? <p><pre>{this.state.track.description}</pre></p> : <Placeholder width="90%" height="40px"/>}

                            <br/>
                            {this.state.loaded 
                            ?   <div>
                                    <DifficultyBar value={this.state.track.difficulty}/> 
                                </div>
                            : <Placeholder width="90%" height="70px"/>}
                            <br/>
                            {this.state.loaded ? <TyreType id={"tyre_type"} typeNumber={this.state.track.tyres} />: <Placeholder width="90%" height="130px"/>}

                            <br/>
                            <div className="TrackInfoViewer--elements--buttonbar">
                                <CircleButton text={"Поделиться"} img={share_icon} onClick={this.share_track}/>
                                <CircleButton text={"Навигатор до старта"} img={navigation_arrow} onClick={this.navigate_to_direction}/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

