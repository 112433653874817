import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./CircleButton.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const CircleButton = ({text, img, disabled, error, color, onClick}) => {        
    let classname = disabled == true ? "circle_button circle_button--disabled" : "circle_button circle_button--primary"
    if (!disabled){
        classname = error == true ? "circle_button circle_button--error" : "circle_button circle_button--primary"
    }
    let classname_color = color != undefined ? "circle_button circle_button--" + color : "circle_button circle_button--primary"
    return (
        <div className={classname} onClick={onClick}>
            {img  ? <img src={img}/> : <></>}
            {/* <div className={classname_color}/> */}
            <p>{text}</p>
        </div>
    )
}


// Button.propTypes = {
//     // className: PropTypes.string,
//     color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ternary']),
//     // size: PropTypes.oneOf(['small', 'medium', 'large']),
//     fixed_width: PropTypes.string,
//     fixed_height: PropTypes.string,
//     child: PropTypes.node,
//     onClick: PropTypes.func,
// };


// Button.defaultProps = {
//     onClick: undefined,
// };

