import React from 'react';

import { CloseButton } from '../../atoms/CloseButton/CloseButton';

import { Placeholder } from '../../atoms/Placeholder/Placeholder'

import { MiniMapView } from '../../organisms/MiniMapView/MiniMapView';

import { Button } from '../../atoms/Button/Button';

import { ProgressBarSteps } from '../../atoms/ProgressBarSteps/ProgressBarSteps';

import { TyreType } from '../../molecules/TyreType/TyreType';


import { CircleButton } from '../../atoms/CircleButton/CircleButton';



import './TrackAddViewer.scss';

import { fetchModule } from '../../fetch'
import { cookies } from '../../cookies';
import { badge } from '../../utils/badge';

import { metric } from '../../metric';


import navigation_arrow from "../../assets/icons/navigation_arrow.svg";
import share_icon from "../../assets/icons/share_icon.svg";


import {serverUrl} from "../../fetch";
import { element } from 'prop-types';
import { async } from 'q';


// export const TrackAddViewer = ({id, closeFunc}) => {
export class TrackAddViewer extends React.Component {

    constructor({id, closeFunc}) {
        super();
        this.id = id;
        this.closeFunc = closeFunc
        let step_from_cookie = cookies.get("map_add_step");
        const track_id_from_cookie = cookies.get("map_add_track_id");

        if (step_from_cookie === undefined) { 
            step_from_cookie = 1
        } else {
            step_from_cookie = parseInt(step_from_cookie);
        }
        let backward_button_function = undefined
        if ((step_from_cookie < 1) || (step_from_cookie > 4)){
            step_from_cookie = 1;
        }
        if (step_from_cookie == 1) {
            backward_button_function = this.confirmAddStop;
        } else if (step_from_cookie == 4)  {
            backward_button_function = this.confirmFormFinish;
        } else {
            backward_button_function = this.back_button_click;
        }        
        
        let backward_button_text = "";
        if (track_id_from_cookie){
            if (step_from_cookie == 1) {
                backward_button_text = "Отменить добавление";
            } else if (step_from_cookie == 4) {
                backward_button_text = "Закрыть";
            } else {
                backward_button_text = "Назад";
            }
        }
        
        
        console.log("step_from_cookie: ", step_from_cookie);
        let file_choice_button_color = "blue";
        let steps = this.getStepsArray(step_from_cookie);
        

        this.state = {
            backward_button_text: backward_button_text,
            backward_button_function: backward_button_function,
            error_msg: "",
            suggest_msg: "",
            has_error: false,
            map_uploaded: false,
            step: step_from_cookie ? step_from_cookie : 1,
            step_names: ["", "Карта", "Информация", "Почта", "Готово"],
            steps: steps,
            map_element: <></>,
            file_choice_button_color: file_choice_button_color,
            track_add_info: {
                id: track_id_from_cookie ? track_id_from_cookie : "",
                file_name: "",
                description: "",
                email: ""
            }
        }
    }

    getStepsArray = (step_index) => {
        const step_names =  ["", "Карта", "Информация", "Почта", ""];
        let steps = [];
        step_names.forEach((name, index)=> {
            if (name !== ""){
                let type = "";
                if (index < step_index){
                    type = "completed";
                } else if(index === step_index) {
                    type = "active";
                }
                let step = {
                    number: index,
                    name: name,
                    type
                }
                steps.push(step);
            }
        })
        return steps;
    }

    componentDidMount = async () => {
        metric.page_visit("/add_track");
        await this.checkAndGetTrackInfo();
    }

    checkAndGetTrackInfo = async () => {
        if (this.state.track_add_info.id) {
            console.log("state track_id_from_cookie: ", this.state.track_add_info.id);
            await this.getTrackInfo(this.state.track_add_info.id);
        }
    }

    getTrackInfo = async (track_id) => {
        try {
          const response = await fetchModule.doGet({path: '/track/'+track_id});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              console.log("JSON: ", json);
              let track_add_info = this.state.track_add_info;
              track_add_info.description = json.description ? json.description : "";
              track_add_info.file_name = json.file_name ? json.file_name : "";
              track_add_info.email = json.author_email ? json.author_email : "";
              console.log("track_add_info: ", track_add_info, json.author_email);

              let map_element = <MiniMapView onTrackClick={this.onTrackClick} geoJSONLink={serverUrl + '/track/' + json.id + '/geojson'}/>;
              
              this.setState(state => ({
                ...state, 
                track_add_info: track_add_info,
                map_uploaded: true,
                map_element: map_element
              }));
              metric.received_data('/track/'+track_id)
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            metric.error("/track", {track_id: track_id, http_code: response.status});
            let json = await response.json();
            return json;
          } 
          else {
                metric.error("/track", {track_id: track_id, http_code: response.status});
                throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    uploadTrack = async (track_kml_data, file_name) => {
        try {
            let body = {
                "kml": track_kml_data,
                "file_name": file_name
            };
            const response = await fetchModule.doPost({path: '/track/map_upload/', body: body});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("json: ", json);
                let track_add_info = this.state.track_add_info;
                track_add_info.id = json.id;
                // тут надо ещё в cookie вписать айдишку редактируемого трека
                cookies.set("map_add_track_id", json.id);
                let map_element = <MiniMapView onTrackClick={this.onTrackClick} geoJSONLink={serverUrl + '/track/' + json.id + '/geojson'}/>;
                console.log("map_element: ", map_element);
                
                this.setState(state => ({
                  ...state, 
                  track_add_info: track_add_info,
                  map_uploaded: true,
                  map_element: map_element
                }));  
                metric.received_data('/map_upload/')
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
              metric.error("/map_upload", {http_code: response.status});
              let json = await response.json();
              return json;
            } 
            else {
                  metric.error("/map_upload", {http_code: response.status});
                  throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onInputChange = async (e) => {
        const fileInput = document.getElementById('kml_file');
        console.log("fileInput.files: ", fileInput.files);
        if (fileInput.files.length == 0){
            return;
        }
        const fileSize = fileInput.files[0].size;
        const file_name = fileInput.files[0].name;
        const maxSize = 1024 * 1024 * 3; // 1MB
        let track_add_info = this.state.track_add_info;
        track_add_info.file_name = file_name;

        if (fileSize > maxSize) {
            this.setState(state => ({
                ...state, 
                track_add_info: track_add_info,
                error_msg: "Файл должен быть меньше 3 МБ.",
                suggest_msg: "Выбранный файл занимает: "+ (fileSize / (1024 * 1024)).toFixed(2) + " МБ.",
                has_error: true,
                map_element: <></>
            }));  
            return 
        }
        // Upload the file
        this.setState(state => ({
            ...state, 
            track_add_info: track_add_info,
            file_choice_button_color: "green",
            map_element: <></>
        }));  
        
        const reader = new FileReader();
        reader.onload = async (e) => {
            
            await this.uploadTrack(e.target.result, file_name);
        };
        // Read in the image file as a data URL.
        reader.readAsText(fileInput.files[0]);
        // 
    }

    confirmTrackMap = (e) => {
        let backward_button_text = "Назад";
        const backward_button_function = this.back_button_click;
        let steps = this.getStepsArray(2);
        this.setState(state => ({
            ...state, 
            step: 2,
            steps: steps,
            backward_button_text: backward_button_text,
            backward_button_function: backward_button_function
        }));
        
        cookies.set("map_add_step", 2);
        badge.set(1);
    }

    textFieldUpdate = async (value_key, value) => {
        let body = {};
        body[value_key] = value;
        
        try {
            const track_id = this.state.track_add_info.id;
            const response = await fetchModule.doPut({path: '/track/' + track_id, body: body});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("json: ", json);
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                metric.error("/track_put", {http_code: response.status});
                let json = await response.json();
                return json;
            } 
            else {
                metric.error("/track_put", {http_code: response.status});
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onTextAreaUpdate = async (e) => {
        const element = document.getElementById("track_description_textarea");
        const value = element.value;
        let track_add_info = this.state.track_add_info;
        track_add_info.description = value;
        // console.log("element: ", element.value);
        if (
                value.endsWith(" ") ||
                value.endsWith(".") ||
                value.endsWith(",") ||
                value.endsWith("?") ||
                value.endsWith("!")
            ){
            await this.textFieldUpdate("description", value);
        }
        this.setState(state => ({
            ...state, 
            track_add_info: track_add_info
        }));
    }

    confirmTrackDescription = async () => {
        element = document.getElementById("track_description_textarea");
        console.log("element: ", element);
        const value = element.value
        let track_add_info = this.state.track_add_info;
        track_add_info.description = value;
        console.log("0) value", value);
        console.log("1) this.state.track_add_info", this.state.track_add_info, track_add_info);
        await this.textFieldUpdate("description", value);
        console.log("2) this.state.track_add_info", this.state.track_add_info, track_add_info);
        let steps = this.getStepsArray(3);
        this.setState(state => ({
            ...state, 
            step: 3,
            steps: steps,
            track_add_info: track_add_info
        }));
        cookies.set("map_add_step", 3);
        console.log("3) this.state.track_add_info", this.state.track_add_info, track_add_info);
    }

    onEmailInputChange = async (e) => {
        element = document.getElementById("track_email_input");
        let track_add_info = this.state.track_add_info;
        track_add_info.email = element.value;
        
        await this.textFieldUpdate("author_email", element.value);
        this.setState(state => ({
            ...state, 
            track_add_info: track_add_info
        }));
    }

    
    confirmEmail = async (e) => {
        // email_confirm
        element = document.getElementById("track_email_input");
        console.log("element: ", element);
        let track_add_info = this.state.track_add_info;
        track_add_info.email = element.value;

        await this.textFieldUpdate("author_email", element.value);
        await this.textFieldUpdate("status", "ready_for_moderation");
        let steps = this.getStepsArray(4);
        this.setState(state => ({
            ...state, 
            step: 4,
            steps: steps,
            track_add_info: track_add_info,
            backward_button_text: "Закрыть",
            backward_button_function: this.confirmFormFinish
        }));
        cookies.set("map_add_step", 4);
    }


    confirmFormFinish = async (e) => {
        console.log("Finish: ");
        badge.delete();

        // удаляем cookie
        cookies.delete("map_add_step");
        cookies.delete("map_add_track_id");
        // закрываем окно
        this.closeFunc();
    }

    confirmAddStop = async (e) => {
        console.log("Add stop: ");
        badge.delete();

        // status –> on_moderation

        await this.textFieldUpdate("status", "stopped_from_user");

        // удаляем cookie
        cookies.delete("map_add_step");
        cookies.delete("map_add_track_id");
        // закрываем окно
        this.closeFunc();
    }


    back_button_click = (e) => {
        console.log("click")
        console.log("this.state.track_add_info", this.state.track_add_info);
        if (this.state.step) {
            const new_step = this.state.step - 1;
            console.log("new_step: ", new_step);
            let backward_button_text = new_step == 1 ? "Отменить добавление" : "Назад";
            const backward_button_function = (new_step == 1) ? this.confirmAddStop : this.back_button_click;
            let steps = this.getStepsArray(new_step);
            this.setState(state => ({
                ...state, 
                step: new_step,
                steps: steps,
                backward_button_text: backward_button_text,
                backward_button_function: backward_button_function
            }));
            cookies.set("map_add_step", new_step);
        }
    }

    render = () => {
        return(
            <div className="TrackAddViewer" key={this.id.toString()}>
                <div>
                    <div className="TrackAddViewer--elements">
                        <div className="TrackAddViewer--elements--header">
                            <h3>Добавление маршрута</h3>
                            <CloseButton text={"Закрыть"} onClick={this.closeFunc}/>
                        </div>
                        <br></br>
                        <ProgressBarSteps steps={this.state.steps} />
                        <div className="TrackAddViewer--elements--backward_button">
                            <p onClick={this.state.backward_button_function}>{this.state.backward_button_text}</p>
                        </div>
                        
                        {/* Шаг: {this.state.step}
                        <p>{this.state.step_names[this.state.step]}</p> */}
                        <br></br>
                        <div className='scrollable'>
                            {this.state.step == 1
                            ?
                            <div className='TrackAddViewer--file_choice'>
                                <h3>Прикрепите KML файл маршрута:</h3>
                                <input type="file" name="kml_file" id="kml_file" className="TrackAddViewer--inputfile" accept=".kml" onChange={this.onInputChange}/>
                                {this.state.track_add_info.file_name 
                                ? 
                                    <div className='TrackAddViewer--inputfile--chosen_file'><h4><i>{this.state.track_add_info.file_name}</i> <label className="TrackAddViewer--inputfile--label" htmlFor="kml_file"><u>изменить</u></label></h4></div>
                                : <></>}
                                {this.state.has_error 
                                ? 
                                <>
                                    <p className='TrackAddViewer--inputfile--error_msg'>{this.state.error_msg}</p>
                                    <p>{this.state.suggest_msg}</p>
                                </>
                                : <></>
                                }
                                {this.state.map_uploaded 
                                ? 
                                <>
                                    <div className="TrackAddViewer--elements--minimap">
                                        {this.state.map_element}
                                    </div>
                                    
                                    <Button text={"Продолжить"} color={this.state.file_choice_button_color} onClick={this.confirmTrackMap}/>
                                </> 
                                : <label className="TrackAddViewer--inputfile--label" htmlFor="kml_file"><Button text={"Выбрать файл"} color={this.state.file_choice_button_color} /></label>}
                                
                            </div>
                            : <></>
                            }

                            {this.state.step == 2
                            ?
                            <div className='TrackAddViewer--description'>
                                <h3>Описание маршрута:</h3>
                                <p>
                                Что встречалось на маршруте? Пригодилась ли лебедка? На какой резине проходили маршрут?
                                Все что хотите рассказать про этот маршрут.
                                </p>
                                <textarea className='TrackAddViewer--description--area' key={"track_description_textarea"} id={"track_description_textarea"} onChange={this.onTextAreaUpdate} onBlur={this.onTextAreaUpdate} defaultValue={this.state.track_add_info.description} ></textarea> 
                                <Button text={"Продолжить"} color={"blue"} onClick={this.confirmTrackDescription}/>
                            </div>
                            : <></>
                            }

                            {this.state.step == 3
                            ?
                            <div className='TrackAddViewer--email'>
                                <p>После получения вашей заявки мы обработаем её и вернемся к вам за уточнением деталей. </p>
                                <p>Обычно мы успеваем ответить в течении 2-3 дней.</p>
                                <br></br>
                                <h3>Адрес вашего email:</h3>
                                <input type="email" className='TrackAddViewer--email--input' key={"track_email_input"} id={"track_email_input"} onChange={this.onEmailInputChange} onBlur={this.onEmailInputChange} defaultValue={this.state.track_add_info.email} ></input> 
                                <Button text={"Отправить маршрут"} color={"green"} onClick={this.confirmEmail}/>
                            </div>
                            : <></>
                            }

                            {this.state.step == 4
                            ?
                            <div className='TrackAddViewer--email'>
                                <p>Ваш маршрут успешно отправлен. Мы вернемся к вам с письмом на почту в течении 2-3 дней.</p>
                                <div className="TrackAddViewer--elements--minimap">
                                    <MiniMapView onTrackClick={this.onTrackClick} geoJSONLink={serverUrl + '/track/' + this.state.track_add_info.id + '/geojson'}/>
                                </div>
                                <br></br>
                                <Button text={"Закрыть окно"} color={"blue"} onClick={this.confirmFormFinish}/>
                            </div>
                            : <></>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

