import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./DifficultyBar.scss";

import difficulty_icon from "../../assets/icons/difficulty.svg";




export const DifficultyBar = ({id, value, min=1, max=100, readOnly=true, onChange=null}) => {        

    return (
        <div className="slidecontainer">
            <input type="range" min={min} max={max} value={value} onChange={onChange} readOnly={readOnly} className="slider" id={id}></input>
            <div className='icon'>
                <img src={difficulty_icon}/>
                <p>Сложность</p>
            </div>
            
        </div>
        
    )
}

