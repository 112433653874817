import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';


import { MapView } from '../../organisms/MapView/MapView';

import {TrackInfoViewer} from '../../organisms/TrackInfoViewer/TrackInfoViewer';
import {TrackAddViewer} from '../../organisms/TrackAddViewer/TrackAddViewer';

import './BigMap.scss';
import { none } from 'ol/centerconstraint';

import { metric } from '../../metric';


export class BigMap extends React.Component {
    constructor(props = {}) {
        // open_edit_form_trigger={this.state.open_edit_form_trigger}
        super();
        console.log("props: ", props);
        this.check_track_id = props.check_track_id;
        this.is_edit_form = props.is_edit_form;
        this.state = {
            to_render: <></>,
            is_edit_form: props.is_edit_form,
            direct_id_viewer_used: false,
            track_id_viewing: null,
            open_edit_form_trigger: props.open_edit_form_trigger
        }
    }

    componentDidMount = () => {
        this.setupPopStateEvent();
        if (this.check_track_id){
            this.checkTrackIdAndOpen();
        }
        console.log("this.check_track_id: ", this.check_track_id);
        console.log("this.is_edit_form: ", this.is_edit_form);
        if (this.is_edit_form){
            this.openTrackAddForm();
        }
    }

    openTrackAddForm = () => {
        // здесь уже просто в рендер кладем форму trackAddViewer.
        // нужно ещё в форму прокинуть функцию закрытия формы – this.closeTrackAddViewer
        // TrackAddViewer
        console.log("Opening add: ", this.is_edit_form);
        const toRender = <TrackAddViewer id={"add_form"} closeFunc={this.closeTrackAddViewer}/>
        this.setState(state => ({
            ...state,
            to_render: toRender,
        }));
        // window.history.pushState({}, "", "/add");
        metric.click("add_track_form");
    }
      

    setupPopStateEvent = () => {
        window.addEventListener('popstate', function(event) {
            // The popstate event is fired each time when the current history entry changes.

            console.log("POPSTATE: ", window.location.pathname);
            if (window.location.pathname.includes("track")){
                window.location.reload();
            } else if (window.location.pathname.includes("add")){
                window.location.reload();
            } else if (window.location.pathname == "/") {
                window.location.reload();
            } 
        
        }, false);
    }
  
    componentWillUnmount = () => {
      // console.log("do you whant to leave?")
      // alert("do you whant to leave?");
    }

    checkTrackIdAndOpen = () => {
        let pathname = window.location.pathname;
        if (pathname.includes("track")) {
            let splitted = pathname.split("/")
            let track_id = splitted[2];
            this.onTrackClick(track_id, null, true);
        }
    }

    closeTrackInfoViewer = () => {
        // console.log("select: ", select);
       
        if (!this.state.direct_id_viewer_used){
            this.state.select.getFeatures().clear();
        }
        this.setState(state => ({
            ...state,
            to_render: <></>,
            direct_id_viewer_used: false
        }));

        window.history.pushState({}, "", "/");
        metric.click("closeTrackInfoViewer_Button");
        
    }

    closeTrackAddViewer = () => {
        this.setState(state => ({
            ...state,
            to_render: <></>,
        }));

        window.history.pushState({}, "", "/");
        metric.click("closeTrackAddViewer_Button");
    }

    onTrackClick = (e, select, direct_id=false) => {
        // select.getFeatures().clear();     // – очищает выбор трека
        
        if (direct_id) {
            const toRender = <TrackInfoViewer id={e} closeFunc={this.closeTrackInfoViewer}/>
            this.setState(state => ({
                ...state,
                to_render: toRender,
                direct_id_viewer_used: true,
                track_id_viewing: e
            }));
            window.history.pushState({}, "", "/track/"+e);
            metric.click("track", {id: e});
            return;
        }
        
        if (e.selected.length){
            const selected_track_id = e.selected[0].values_.id;
            const toRender = <TrackInfoViewer id={selected_track_id} closeFunc={this.closeTrackInfoViewer}/>
            this.setState(state => ({
                ...state,
                to_render: toRender,
                select: select,
                track_id_viewing: selected_track_id
            }));
            window.history.pushState({}, "", "/track/"+selected_track_id);
            metric.click("track", {id: selected_track_id});
        }
        if (e.deselected.length){
            this.setState(state => ({
                ...state,
                to_render: <></>,
                track_id_viewing: null
            }));
            select.getFeatures().clear();
            metric.click("closeTrackInfoViewer_Map");
        }
    }
  
    render = () => {
      return (
            <div className="BigMap">
              <MapView onTrackClick={this.onTrackClick} performFlyTo={true}/>
              
              {this.state.to_render}
            </div>
        );
      }
  }
  
  export default BigMap;