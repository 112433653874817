import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./Thumbler.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const Thumbler = ({id, text, img, disabled, error, color, onClick, checked, onChange}) => {        
    let classname_color = color != undefined ? "button_thumbler button_thumbler--" + color : "button_thumbler"
    let classname_color_label = color != undefined ? "light light--" + color : "light"
    let classname_color_dots = color != undefined ? "dots dots--" + color : "dots"
    return (

        <div className='switch_container'>
            <label className='switch'>
                <input id={id} type='checkbox' defaultChecked={checked} onChange={onChange} />
                <div className={classname_color}>
                    <div className={classname_color_label}></div>
                    <div className={classname_color_dots}></div>
                    <div className='characters'>
                    </div>
                    <div className='shine'></div>
                    <div className='shadow'></div>
                </div>
                
            </label>
            <p>{text}</p>
        </div>
        
    )
}


// Button.propTypes = {
//     // className: PropTypes.string,
//     color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ternary']),
//     // size: PropTypes.oneOf(['small', 'medium', 'large']),
//     fixed_width: PropTypes.string,
//     fixed_height: PropTypes.string,
//     child: PropTypes.node,
//     onClick: PropTypes.func,
// };


// Button.defaultProps = {
//     onClick: undefined,
// };

