import React, {Suspense} from 'react';

import Feature from 'ol/Feature.js';
import Geolocation from 'ol/Geolocation.js';
import Map from 'ol/Map.js';
import Point from 'ol/geom/Point.js';
import View from 'ol/View.js';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {ATTRIBUTION} from 'ol/source/OSM.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';

import {transform} from 'ol/proj'
import {getCenter} from 'ol/extent';

import './MapView.scss';

import { metric } from '../../metric';

import {serverUrl} from "../../fetch";

import GeoJSON from 'ol/format/GeoJSON.js';

import {
  Select,
  defaults as defaultInteractions,
} from 'ol/interaction.js';
import { none } from 'ol/centerconstraint';



import { Thumbler } from '../../atoms/Thumbler/Thumbler';

import navigation_arrow from "../../assets/icons/navigation_arrow.svg";
import like_icon from "../../assets/icons/like_icon.svg";



const styles = {
  'LineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 3,
    }),
  }),

  'easy': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 3,
      lineDash: [10, 10],
    }),
  }),
  'medium': new Style({
    stroke: new Stroke({
      color: '#FFA318',
      width: 3,
      lineDash: [10, 10],
    }),
  }),
  'hard': new Style({
    stroke: new Stroke({
      color: '#E90000',
      width: 3,
      lineDash: [10, 10],
    }),
  }),

  'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 3,
    }),
  })
};

const styleFunction = function (feature) {
  let style = styles[feature.values_.level];  // styles[feature.getGeometry().getType()];
  return style;
};


export class MapView extends React.Component {
  constructor({onTrackClick, performFlyTo=false}) {
    super();
    this.onTrackClick = onTrackClick;
    this.performFlyTo = performFlyTo;
    let param = "";
    if (document.cookie.includes("map_status")){
      let map_status = "";
      document.cookie.split(" ").forEach((value) => {
        if (value.includes("map_status")){
          value = value.replace("map_status=", "").replace(";", "");
          map_status = value;
          return
        }
      })
      param = "?status="+map_status;
    }
    
    this.state = {
      auth_status_code: 0,
      auth_message: "",
      track_levels: {
        'easy': {
          'url': serverUrl +'/track/geojson/easy' + param,
          'turned_on': false,
          'loaded': false,
          'layer': none
        },
        'medium': {
          'url': serverUrl +'/track/geojson/medium' + param,
          'turned_on': true,
          'loaded': false,
          'layer': none
        },
        'hard': {
          'url': serverUrl +'/track/geojson/hard' + param,
          'turned_on': true,
          'loaded': false,
          'layer': none
        }
      }
    }
    this.map = null;
    
  }

  componentDidMount = () => {
    this.setupSelect();
    this.setupMapView();
    // this.getGeoJSON(this.map, this.layer);
    this.getGeoJSON_levels();
  }

  componentWillUnmount = () => {
    // console.log("do you whant to leave?")
    // alert("do you whant to leave?");
    
  }

  flyTo = (location, done) => {
    const duration = 2000;
    const zoom = Math.max(this.view.getZoom(), 10);
    let parts = 2;
    let called = false;
    function callback(complete) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        done(complete);
      }
    }
    this.view.animate(
      {
        center: location,
        duration: duration,
      },
      callback
    );
    this.view.animate(
      {
        zoom: zoom - 1,
        duration: duration / 2,
      },
      {
        zoom: zoom,
        duration: duration / 2,
      },
      callback
    );
  }


  setupSelect = () => {
    this.select = new Select({
      wrapX: false,
      hitTolerance: 10
    });
    
    this.select.on("select", (e) => {
      if (this.onTrackClick){
        this.onTrackClick(e, this.select);
        if (this.performFlyTo) {
          if (e.selected.length) {
            const tmp_extent = e.selected[0].values_.geometry.extent_;
            this.flyTo(getCenter(tmp_extent), function () {})
          }
        }
      }
      
    })
  }

  setupMapView = () => {
    this.view = new View({
      center: [4222538.68101781, 7479960.285750981],
      zoom: 9,  // делаем 9 и координаты Москвы, потому что начинаем запускаться с этого региона
    });
    
    const map = new Map({
      interactions: defaultInteractions().extend([this.select]),
      layers: [
        new TileLayer({
          source: new OSM(),
        })
        // new TileLayer({
        //   source: new OSM({
        //     attributions: [
        //       'All maps © <a href="https://www.opencyclemap.org/">OpenCycleMap</a>',
        //       ATTRIBUTION,
        //     ],
        //     url:
        //       // 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&lang=ru_RU'
        //       // 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png'
        //       // 'https://{a-c}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
        //       // 'https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png'
              

        //       // 'https://tile-{a-c}.openstreetmap.fr/hot/{z}/{x}/{y}.png'   // вот это работает, но медленно
              
              
        //       // 'https://{a-c}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png'
        //       // '?apikey=Your API key from https://www.thunderforest.com/docs/apikeys/ here',
        //   }),
        // }),
      ],
      target: 'map',
      view: this.view,
      controls: []
    });
    
    this.map = map;
    
    const geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });
    
    function el(id) {
      return document.getElementById(id);
    }
    
    // el('track').addEventListener('change', function () {
    //   geolocation.setTracking(this.checked);
    // });
    
    // update the HTML page when the position changes.
    geolocation.on('change', function () {
      console.log("geolocation position: ", geolocation.getPosition());
      console.log("geolocation projection: ", geolocation.getProjection());
      
      el('accuracy').innerText = geolocation.getAccuracy() + ' [m]';
      el('altitude').innerText = geolocation.getAltitude() + ' [m]';
      el('altitudeAccuracy').innerText = geolocation.getAltitudeAccuracy() + ' [m]';
      el('heading').innerText = geolocation.getHeading() + ' [rad]';
      el('speed').innerText = geolocation.getSpeed() + ' [m/s]';
    });
    
    // handle geolocation error.
    geolocation.on('error', function (error) {
      const info = document.getElementById('info');
      info.innerHTML = error.message;
      info.style.display = '';
    });
    
    const accuracyFeature = new Feature();
    geolocation.on('change:accuracyGeometry', function () {
      accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
    });
    
    const positionFeature = new Feature();
    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );
    
    geolocation.on('change:position', function () {
      const coordinates = geolocation.getPosition();
      positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
    });
    
    new VectorLayer({
      map: map,
      source: new VectorSource({
        features: [accuracyFeature, positionFeature],
      }),
    });
  }


  getGeoJSON = (map) => {
    const url = '/style.geojson';    //'http://localhost:3000/style.geojson'; // '/style.geojson'; //'https://openlayers.org/data/vector/ecoregions.json';

    const some_layer = new VectorLayer({
      map: map,
      source: new VectorSource({
        url: url,
        format: new GeoJSON()
      }),
      style: styleFunction,
    })

    // some_layer.values_.visible = false;

    console.log("some_layer: ", some_layer);
  }

  getGeoJSON_levels = () => {

    let track_levels = this.state.track_levels;

    for (const [key, value] of Object.entries(track_levels)) {
      this.getGeoJSON_level(key);
    }
  }

  getGeoJSON_level = (level) => {

    let track_levels = this.state.track_levels;

    const url = track_levels[level].url;    //'http://localhost:3000/style.geojson'; // '/style.geojson'; //'https://openlayers.org/data/vector/ecoregions.json';
    const some_layer = new VectorLayer({
      map: this.map,
      source: new VectorSource({
        url: url,
        format: new GeoJSON()
      }),
      style: styleFunction,
    })

    track_levels[level].layer = some_layer;
    let visible = track_levels[level]['turned_on']
    track_levels[level].layer.values_.visible = visible;
    track_levels[level].loaded = true;
    this.setState(state => ({
      ...state, 
      track_levels: track_levels,
      loaded: true
    }));  
  }

  switchGeoJSON_level_visible = (level) => {

    let track_levels = this.state.track_levels;
    track_levels[level].turned_on = !track_levels[level].turned_on;
    track_levels[level].layer.values_.visible = !track_levels[level].layer.values_.visible;
    track_levels[level].layer.getSource().changed();
    this.setState(state => ({
      ...state, 
      track_levels: track_levels,
      loaded: true
    }));  
  }

  toggle_switcher = (e) => {
    let level = e.target.id;
    this.switchGeoJSON_level_visible(level);
    let turned_on = this.state.track_levels[level]["turned_on"]
    metric.click("track_level_toggle", {level: e.target.id, turned_on: turned_on})
  }

  render = () => {
    return (
          <div className="MapView">            

              <div id="map" className="map">

                <div className="extra">
                </div>
              
                <div id="info">
                
                </div>
                
                {/* <label htmlFor="track">
                  track position
                  <input id="track" type="checkbox"/>
                </label>
                <p>
                  position accuracy : <code id="accuracy"></code>&nbsp;&nbsp;
                  altitude : <code id="altitude"></code>&nbsp;&nbsp;
                  altitude accuracy : <code id="altitudeAccuracy"></code>&nbsp;&nbsp;
                  heading : <code id="heading"></code>&nbsp;&nbsp;
                  speed : <code id="speed"></code>
                </p> */}
              </div>

              <div className='MapView--elements--buttonbar-placement'>
                <div className="MapView--elements--buttonbar">
                    <Thumbler id={"easy"} text={"Легкий"} color={"green"} checked={this.state.track_levels.easy.turned_on} onClick={this.toggle_switcher} onChange={this.toggle_switcher}/>
                    <Thumbler id={"medium"} text={"Средний"} color={"yellow"} checked={this.state.track_levels.medium.turned_on} onChange={this.toggle_switcher}/>
                    <Thumbler id={"hard"} text={"Сложный"} color={"red"} checked={this.state.track_levels.hard.turned_on} onChange={this.toggle_switcher}/>
                </div>
              </div>
              
          </div>
      );
    }
}

export default MapView;
